module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PIAIC-Certification-Portal","short_name":"PIAIC","start_url":"/","background_color":"#017969","display":"minimal-ui","icon":"src/assets/LogoPIAIC.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fb7de414d46d87338532689f3beca3a0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
